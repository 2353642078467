import { timeFormat, numberFormat } from "@custom/index";
export function apartmentColumn(_this) {
    return [
        {
            type: "selection",
            width: 55,
            fixed: 'left'
        },
        {
            type: "index",
            label: "序号",
            width: 80,
            fixed: 'left'
        },
        {
            prop: "name",
            label: "房源",
            fixed: 'left',
            render: (h, {row}) => {
                const {name} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
                        }
                    }
                }, name);
            }
        },
        // 2024/02/05 张晓瑜注释房源类型
        // {
        //     prop: "propertyCode",
        //     label: "房源类型",
        //     fixed: 'left',
        //     render: (h, { row }) => {
        //         const propertyCode =_this.propertyCodeList.find(v => v.code === row.propertyCode);
        //         const codeName = propertyCode ? ((propertyCode.code.toString()).startsWith("60021")?"保障性住房:":"长租公寓:")+ propertyCode.codeName : "--";
        //         return h("span", (codeName));
        //     },
        // },
        {
            prop: "status",
            label: "出租状态",
            render: (h, {row}) => {
                const {status} = row;
                let typeName = "";
                switch (status) {
                    case 600001:
                        typeName = "空置";
                        break;
                    case 600002:
                        typeName = "预订";
                        break;
                    case 600003:
                        typeName = "已租";
                        break;
                    case 600004:
                        typeName = "欠租";
                        break;
                    case 600005:
                        typeName = "到期";
                        break;
                    case 600006:
                        typeName = "维修中";
                        break;
                    case 600007:
                        typeName = "待保洁";
                        break;
                        
                }
                return h("span", (typeName));
            }
        },
        {
            prop: "publishStatus",
            label: "发布状态",
            render: (h, {row}) => {
                const {publishStatus} = row;
                let typeName = "";
                switch (publishStatus) {
                    case 1:
                        typeName = "待发布";
                        break;
                    case 2:
                        typeName = "已发布";
                        break;
                }
                return h("span", (typeName));
            }
            
        },
        {
            prop: "updateTime",
            label: "发布时间",
            render: (h, { row }) => {
                return timeFormat(row.updateTime?new Date(row.updateTime):"");
            }
        },
        {
            prop: "monthAmount",
            label: "发布租金(元/月)",
            render: (h, { row }) => {
                return h("span", numberFormat(row.monthAmount / 100));
            }
        },
        /*{
            prop: "publishArea",
            label: "面积",
            render: (h, { row }) => {
                return h("span", row.publishArea?row.publishArea+"㎡":"0");
            },
            width: 100
        },*/
    ];
}
export function batchColumn(_this) {
    return [
        {
            type: "index",
            label: "序号",
            width: 80,
            fixed: 'left'
        },
        {
            label: "房源",
            width:200,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h( "span", (apartmentName));
            }
        },
        {
            label: "租金方式",
            render: (h, {row}) => {
                const {rentalAmountTypeName} = row;
                return h( "span", (rentalAmountTypeName));
            }
        },
        {
            prop: "monthPriceAmount",
            label: "单价(元/㎡/月)",
            render: (h, {row}) => {
                const {monthPriceAmount} = row;
                return h("span", numberFormat(monthPriceAmount / 100));
            }
        },
        {
            prop: "outsideArea",
            label: "建筑面积(㎡)",
            render: (h, {row}) => {
                const {outsideArea} = row;
                return h( "span", (outsideArea));
            }
        },
        {
            prop: "rentalAmount",
            label: "发布租金(元/月)",
            render: (h, {row}) => {
                const {rentalAmount} = row;
                return h("span", numberFormat(rentalAmount / 100));
            }
        },
    ];
}