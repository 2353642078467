<template>
    <div class="publish-apartment">
        <div class="top-bar bg-white">
            <el-select v-model="formSearch.uuid" placeholder="请选择小区" filterable clearable @change="handleChange2"
                       size="small">
                <el-option v-for="item in communityList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
            </el-select>
            <el-select v-model="formSearch.status" placeholder="请选择出租状态" filterable clearable 
                       size="small">
                <el-option v-for="(item,index) in billStatusTypes" :key="index" :label="item.codeName" :value="item.code"></el-option>
            </el-select>
            <el-select v-model="formSearch.publishStatus" placeholder="请选择发布状态" @change="handleChange"
                       style="margin: 0 7px" size="small">
                <el-option v-for="item in statuses" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-input v-model="formSearch.keyword" placeholder="请输入房源/房型" style="margin-right: 9px; width: 250px"
                      size="small"/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button @click="formSearch.publishStatus=1;formSearch.keyword='';formSearch.status='';formSearch.uuid = ''; handleChange()" size="small">重置</el-button>
            <el-button type="primary" @click="clickBatch('publish')" size="small">批量发布</el-button>
            <el-button type="primary" @click="clickBatch('cancel')" size="small">批量取消</el-button>
           <!-- <el-button size="small" v-if="multipleSelection.length > 1" @click="oneClickRelease">一键发布</el-button> -->
        </div>
        <r-e-table class="bg-white" ref="apartmentTableRef" :dataRequest="getApartmentTableData" :height="820"
                   :columns="apartmentColumn" :query="formSearch" @selection-change="handleSelectionChange">
            <el-table-column slot="customColumn" label="查看房型" width="350" fixed="left">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="$refs.publishRef2.layerAddEditPublishInit2(row)">
                            {{ row.publishTemplateName || "查看" }}
                        </span>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column slot="customColumn" label="金额" width="200" v-if="formSearch.publishStatus===2">
                <template slot-scope="{ row }">{{ (row.amount/100).toFixed(2) }}</template>
            </el-table-column> -->
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <!--<span class="table-btn" @click="handleEditPublish(row)">查看</span>-->
                        <span class="table-btn" v-if="formSearch.publishStatus === 1" @click="clickBatch('publish', row)">发布</span>
                        <span class="table-btn" @click="clickBatch('cancel', row)">取消</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <!--   查看房源详情弹框       -->
        <layer-edit-publish class="layer-edit-publish" ref="publishRef"/>
        <!--   发布弹框       -->
        <r-e-dialog title="发布" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel">
<!--          2023-11-23--->
            <el-form title="" ref="formPublish" label-width="130px" size="small" :model="formPublish" :rules="rules">
                <el-form-item label="地址" prop="name">
                    <el-input disabled v-model="formPublish.name"/>
                </el-form-item>
              <el-form-item label="房租计算规则" prop="name">
                <el-input disabled v-model="formPublish.name"/>
              </el-form-item>
                <el-form-item label="月租金(元/月)" prop="monthAmount">
                    <el-input type="number" v-model="formPublish.monthAmount"/>
                </el-form-item>
                <el-form-item label="季租金(元/月)" prop="seasonAmount">
                    <el-input type="number" v-model="formPublish.seasonAmount"/>
                </el-form-item>
                <el-form-item label="半年租金(元/月)" prop="halfAmount">
                    <el-input type="number" v-model="formPublish.halfAmount"/>
                </el-form-item>
                <el-form-item label="年租金(元/月)" prop="yearAmount">
                    <el-input type="number" v-model="formPublish.yearAmount"/>
                </el-form-item>
                <el-form-item label="押金(元/月)" prop="bone">
                    <el-input type="number" v-model="formPublish.bone"/>
                </el-form-item>
                <el-form-item label="定金(元/月)" prop="deposit">
                    <el-input type="number" v-model="formPublish.deposit"/>
                </el-form-item>
                <el-form-item label="服务费(元/月)" prop="tipAmount">
                    <el-input type="number" v-model="formPublish.tipAmount"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <!-- 一键发布弹框 -->
        <r-e-dialog title="一键发布" :visible="OnekeyDialogVisible" show-footer @click-submit="oneClickReleaseSubmit"
                @click-cancel="handleOneCancel" @close="handleOneCancel">
            <el-form title="" ref="formOnekeyPublish" label-width="110px" size="small" :model="oneKeyFormPublish"
                     :rules="oneKeyRules">
                <el-form-item label="月租金(元/月)" prop="monthAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.monthAmount" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="季租金(元/月)" prop="seasonAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.seasonAmount" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="半年租金(元/月)" prop="halfAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.halfAmount" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="年租金(元/月)" prop="yearAmount">
                    <el-input type="number" v-model="oneKeyFormPublish.yearAmount" @input="$forceUpdate()"/>
                </el-form-item>

                <el-form-item label="押金(元/月)" prop="bone">
                    <el-input type="number" v-model="oneKeyFormPublish.bone" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="定金(元/月)" prop="deposit">
                    <el-input type="number" v-model="oneKeyFormPublish.deposit" @input="$forceUpdate()"/>
                </el-form-item>
                <el-form-item label="服务费(元/月)" prop="tipAmount" v-if="oneKeyFormPublish.propertyCode!==600221">
                    <el-input type="number" v-model="oneKeyFormPublish.tipAmount" @input="$forceUpdate()"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>


        <!--   查看房型弹框       -->
        <layer-add-edit-publish class="layer-add-edit-publish" ref="publishRef2"/>

        <layer-bzf-publish ref="layerBzfPublish" @handleSearch="handleChange"/>
        <!-- layer-publish-batch -->
        <layer-publish-batch ref="layerPublishBatch" @handleSearch="handleChange"/>

    </div>
</template>

<script>
    import {cancelPublish, getApartmentByPublishStatus, getCommunityListApi, publishApartment} from "@/api/publish";
    import {isbzf, apartmentDiscountAmountInfo, getCzhiListApi} from "@/api/house-resource";
    import {apartmentColumn} from "@/views/rental-management/publish-apartment/data";
    import {layerEditPublish} from "@/views/rental-management/publish-apartment/components";
    import {MessageBoxConfirm, MessageSuccess, MessageWarning, MessageError} from "@custom/message";
    import {layerAddEditPublish} from "@/views/rental-management/publish-create/components";
    import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
    export default {
        name: "publish-apartment",
        data() {
            return {
                apartmentColumn: apartmentColumn(this),
                statuses: [{
                    id: 1,
                    name: "待发布"
                }, {
                    id: 2,
                    name: "已发布"
                }],
                communityList: [],
                list: [],
                community: "",
                formSearch: {
                    publishStatus: 1
                },
                data: {
                    publishUuid: "",
                    apartmentUuids: []
                },
                dialogVisible: false,
                OnekeyDialogVisible: false,
                formPublish: {
                    apartmentUuid: null,
                    name: null,
                    monthAmount: null,
                    seasonAmount: null,
                    halfAmount: null,
                    yearAmount: null,
                    bone: null,
                    deposit: null,
                    tipAmount: null,
                },
                rules: {
                    bone: [{required: true, message: "请输入押金", trigger: "blur"},],
                    deposit: [{required: true, message: "请输入定金", trigger: "blur"},],
                    tipAmount: [{required: true, message: "请输入服务费", trigger: "blur"},],
                    monthAmount: [{required: true, message: "请输入月租金", trigger: "blur"},],
                    seasonAmount: [{required: true, message: "请输入季租金", trigger: "blur"},],
                    halfAmount: [{required: true, message: "请输入半年租金", trigger: "blur"},],
                    yearAmount: [{required: true, message: "请输入年租金", trigger: "blur"},],
                },
                multipleSelection: [],
                oneKeyFormPublish: {},
                oneKeyRules: {
                    bone: [{required: true, message: "请输入押金", trigger: "blur"},],
                    deposit: [{required: true, message: "请输入定金", trigger: "blur"},],
                    tipAmount: [{required: true, message: "请输入服务费", trigger: "blur"},],
                    monthAmount: [{required: true, message: "请输入月租金", trigger: "blur"},],
                    seasonAmount: [{required: true, message: "请输入季租金", trigger: "blur"},],
                    halfAmount: [{required: true, message: "请输入半年租金", trigger: "blur"},],
                    yearAmount: [{required: true, message: "请输入年租金", trigger: "blur"},],
                },
                propertyCodeList: [],
                billStatusTypes: [],
            };
        },
        components: { layerEditPublish, layerAddEditPublish,
            layerBzfPublish: () => import("./components/layer-bzf-publish"),
            layerPublishBatch:()=>import("./components/layer-publish-batch")
        },
        async created() {
            // 保障房 600210
            let AffordableHousing=await this.$store.dispatch("app/getDictionaryByCodeActions", "600210");
            // 长租公寓 600220
            let apartmentList=await this.$store.dispatch("app/getDictionaryByCodeActions", "600220");
            this.billStatusTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 600000);

            this.propertyCodeList = [...AffordableHousing,...apartmentList];
        },
        mounted() {
            getCommunityListApi().then(res => {
                this.communityList = res.list;
            });
            this.getList();

        },
        methods: {
            getApartmentTableData(params) {
                return getApartmentByPublishStatus(params);
            },
            getList() {
                getApartmentByPublishStatus(this.formSearch).then(res => {
                    this.list = res.list;
                });
            },
            handleSelectionChange(val) {
				console.log("this.multipleSelection",val);
                this.multipleSelection = val;
            },
            handleChange() {
                // this.formSearch.uuid = "";
                // this.formSearch.status ="";
                this.$refs.apartmentTableRef.pNumber = 1;
                this.$refs.apartmentTableRef.getTableData();
                this.getList();
            },
            handleChange2(e) {
                let data = this.list.filter(v => v.community.uuid === e);
                this.$refs.apartmentTableRef.tableData = e ? data : this.list.slice(0, 10);
                this.$refs.apartmentTableRef.pNumber = 1;
                this.$refs.apartmentTableRef.total = e ? data.length : this.list.length;
            },
            handleEditPublish(row) {
                // 新增、编辑打开弹窗
                this.$refs.publishRef.layerEditPublishInit(row);
            },
            async handlePublish(row) {
                let {
                    propertyCode, name, amount, bone, deposit, tipAmount, uuid, monthAmount, seasonAmount, halfAmount,
                    yearAmount, insideArea, outsideArea, monthPriceAmount, yearDiscount, sixMonthDiscount
                } = row;
                // let re = await isbzf(uuid);
                // alert(re)
             // 调取接口区分小区控制折扣范围
             await getCzhiListApi({uuid:uuid}).then(({info}) => {
               row.percentageMin = info.percentageMin,
                   row.percentageMax = info.percentageMax,
                   row.rentalTypeName = info.rentalTypeName
              })

                // 发布打开弹窗
               this.$refs["layerBzfPublish"].openDialog(row);
                // 触发子组件的折扣一百api
                // if(!re.info) this.$refs["layerBzfPublish"].sixMonthDiscountclieck();
                // else this.dialogVisible = true;

                monthPriceAmount = ParseFloatFormat(monthPriceAmount);

                bone = ParseFloatFormat(bone);
                deposit = ParseFloatFormat(deposit);
                tipAmount = ParseFloatFormat(tipAmount);
                monthAmount = ParseFloatFormat(monthAmount);
                seasonAmount = ParseFloatFormat(seasonAmount);
                halfAmount = ParseFloatFormat(halfAmount);
                yearAmount = ParseFloatFormat(yearAmount);

                this.formPublish = {
                    propertyCode, name, apartmentUuid: uuid, bone, deposit, tipAmount, monthAmount, seasonAmount,
                    halfAmount, yearAmount
                };

            },
            handleCancelPublish(row) {
                MessageBoxConfirm(() => {
                    cancelPublish(row.uuid).then(res => {
                        MessageSuccess(`${ res.msg }`);
                        this.$refs.apartmentTableRef.getTableData();
                    });
                }, `确定取消发布 ${ row.name } 吗？`);
            },
            handleSubmitPublish() {
                let that = this;
                // 表单提交
                this.$refs.formPublish.validate((valid) => {
                    if (valid) {
                        //that.formPublish.amount *= 100;
                        let {bone, deposit, tipAmount, monthAmount, seasonAmount, halfAmount, yearAmount,} = that.formPublish;
                        bone = ParseIntFormat(bone);
                        deposit = ParseIntFormat(deposit);
                        tipAmount = ParseIntFormat(tipAmount);
                        monthAmount = ParseIntFormat(monthAmount);
                        seasonAmount = ParseIntFormat(seasonAmount);
                        halfAmount = ParseIntFormat(halfAmount);
                        yearAmount = ParseIntFormat(yearAmount);

                        that.formPublish.monthAmount = monthAmount;
                        that.formPublish.seasonAmount = seasonAmount;
                        that.formPublish.halfAmount = halfAmount;
                        that.formPublish.yearAmount = yearAmount;
                        that.formPublish.bone = bone;
                        that.formPublish.deposit = deposit;

						if(that.formPublish.propertyCode===600221) that.formPublish.tipAmount = tipAmount;
						else that.formPublish.tipAmount = 0;

                        publishApartment(this.formPublish).then(res => {
                            MessageSuccess(`${ this.formPublish.name } 发布成功!`);
                            that.handleCancel("update");
                        });
                    } else {
                        MessageWarning("请填写必填值");
                        return false;
                    }
                });
            },
            handleCancel(closeType = "cancel") {
                // 取消、关闭弹窗
                this.dialogVisible = false;
                // this.formPublish = {};
                this.$refs.formPublish.resetFields();
                // 新增、编辑成功，需要刷新数据
                if (closeType === "update") this.$refs.apartmentTableRef.getTableData();
            },
            onAmountInput(){
                this.$forceUpdate();
            },
            oneClickRelease(){
                let that = this;
                //2022/03/19 王江毅抽取propertyCode
                let propertyCodeS = that.multipleSelection.map(({propertyCode}) => propertyCode);
                //2022/03/19 王江毅 过滤propertyCode 60021开头的是经济保障房
                let jingji = propertyCodeS.filter(i => i.toString().startsWith("60021"));
                //2022/03/19 王江毅 过滤propertyCode 60022开头的是长租公寓
                let changzu = propertyCodeS.filter(i => i.toString().startsWith("60022"));

                console.log("经济：" + jingji.join(";"), "长租：" + changzu.join(";"));
                //2022/03/19 王江毅 两个数组都不为空说明两种类型的房源都有
                let ok = jingji.length !== 0 && changzu.length !== 0;

                //2022/03/19 王江毅 两种类型房源都有无法发布
                if (ok) MessageError("长租公寓和保障性住房不能一起发布！");
                else {
                    //2022/03/19 王江毅 过滤脏数据
                    let apartmentList = that.multipleSelection.filter(({propertyCode}) => propertyCode !== 0);
                    //2022/03/19 王江毅 抽取uuid
                    let uuids = apartmentList.map(({uuid}) => uuid);
                    console.log("uuids", uuids);
                    that.oneKeyFormPublish.apartmentUuid = uuids.join(";");
                    //2022/03/19 王江毅 changzu.length!=0说明发布的是长租公寓
                    that.oneKeyFormPublish.propertyCode = changzu.length !== 0 ? 60022 : 60021;
                    that.OnekeyDialogVisible = true;
                }
            },
            handleOneCancel(closeType = "cancel") {
                // 取消、关闭弹窗
                this.OnekeyDialogVisible = false;
                this.oneKeyFormPublish = {};
                this.$refs.formOnekeyPublish.resetFields();
                // 新增、编辑成功，需要刷新数据
                if (closeType === "update") this.$refs.apartmentTableRef.getTableData();
            },
            oneClickReleaseSubmit(){
                let that = this;
                // 表单提交
                this.$refs.formOnekeyPublish.validate((valid) => {
                    if (valid) {
                        let {apartmentUuid,monthAmount, seasonAmount, halfAmount, yearAmount, bone, deposit, tipAmount, propertyCode} = that.oneKeyFormPublish;
                        bone = ParseIntFormat(bone);
                        deposit = ParseIntFormat(deposit);
                        monthAmount = ParseIntFormat(monthAmount);
                        seasonAmount = ParseIntFormat(seasonAmount);
                        halfAmount = ParseIntFormat(halfAmount);
                        yearAmount = ParseIntFormat(yearAmount);

                        if(propertyCode===60022) tipAmount = ParseIntFormat(tipAmount);
                        else tipAmount = 0;

                        let paramsData = {apartmentUuid, monthAmount, seasonAmount, halfAmount, yearAmount, bone, deposit, tipAmount};
                        console.log(paramsData);
                        publishApartment(paramsData).then(res => {
                            MessageSuccess(`发布成功!`);
                            that.handleOneCancel("update");
                        });
                    } else {
                        MessageWarning("请填写必填值");
                        return false;
                    }
                });
            },
            // 发布与批量发布
            clickBatch(action, row = null) {
                console.log("action", action);
                let uuids = [];
                // 如果是单行操作（发布或取消），获取当前行的 uuid
                if ((action === 'publish' || action === 'cancel') && row) {
                    uuids = [row.uuid];
                } else if (action === 'publish' || action === 'cancel') {
                    // 如果是批量操作，使用已有的 uuids 数组
                    uuids = this.multipleSelection.map(({ uuid }) => uuid);
                }

                // 打开对话框，传递 uuids 和 action
                this.$refs["layerPublishBatch"].openDialog(uuids, action);
            },
        }
    };

</script>

<style lang="scss" scoped>
     .publish-apartment {
          padding: 0 VW(15px);

          .top-bar {
               display: flex;
               margin: VH(10px) 0 VH(15px);
               padding: VH(15px) VW(15px);
            //    justify-content: center;
          }

          /deep/ .r-e-table {
               .el-table .cell {
                    //white-space: nowrap;
                    text-align: center;
               }
          }
     }
</style>
